@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100%;
  width: 100%;
}

body {
  position: relative;
  display: flex;
  flex: 1;
}

#root {
  display: flex;
  flex: 1;
  flex-direction: column;
}

button:focus {
  outline: none;
}

.disable-dbl-tap-zoom {
  touch-action: manipulation;
}

.checkbox {
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  flex-shrink: 0;
  height: 1.2em;
  width: 1.2em;
  color: #1a84db;
  background-color: #fff;
  border-color: #c9c9c9;
  border-width: 1px;
  border-radius: 0.25rem;
  outline: none;
}

.checkbox:checked {
  background-image: url('data:image/svg+xml,%3csvg viewBox="0 0 16 16" fill="white" xmlns="http://www.w3.org/2000/svg"%3e%3cpath d="M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z"/%3e%3c/svg%3e');
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.button,
button {
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.button:disabled,
button:disabled {
  opacity: 0.5;
  box-shadow: none !important;
  cursor: default;
}

.ripple {
  position: relative;
  overflow: hidden;
}

.ripple::after {
  display: none;
  content: "";
  position: absolute;
  border-radius: 50%;
  background-color: #f9f6f750;

  width: 100px;
  height: 100px;
  margin-top: -50px;
  margin-left: -50px;

  /* Center the ripple */
  top: 50%;
  left: 50%;

  animation: ripple 2s;
  opacity: 0;
  z-index: 9;
}

.ripple:focus:not(:active)::after {
  display: block;
}

@keyframes ripple {
  from {
    opacity: 1;
    transform: scale(0);
  }
  to {
    opacity: 0;
    transform: scale(10);
  }
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 140px;
  z-index: 30;
  min-height: 40px;
}

.dropdown-content .divider {
  border-bottom: 1px solid;
  border-color: #ccc;
  margin: 5px;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #00000024;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #00000040;
}
